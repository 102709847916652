// Daily Traffic Dashboards Default

export const barChartDataDailyTraffic = [
  {
    name: "Daily Traffic",
    data: [20, 30, 40, 20, 45, 50, 30],
  },
];

export const barChartOptionsDailyTraffic = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: ["00", "04", "08", "12", "14", "16", "18"],
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#CBD5E0",
        fontSize: "14px",
      },
    },
  },
  grid: {
    show: false,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      type: "vertical",
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: "#4318FF",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(67, 24, 255, 1)",
            opacity: 0.28,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "40px",
    },
  },
};

// Consumption Users Reports

export const barChartDataConsumption = [
  {
    name: "PRODUCT A",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
  {
    name: "PRODUCT B",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
  {
    name: "PRODUCT C",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
];

export const barChartOptionsConsumption = {
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: ["17", "18", "19", "20", "21", "22", "23", "24", "25"],
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: "black",
    labels: {
      show: false,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  },

  grid: {
    borderColor: "rgba(163, 174, 208, 0.3)",
    show: true,
    yaxis: {
      lines: {
        show: false,
        opacity: 0.5,
      },
    },
    row: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "solid",
    colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
  },
  legend: {
    show: false,
  },
  colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "20px",
    },
  },
};

export const pieChartOptions = {
  labels: ["Your files", "System", "Empty"],
  colors: ["#4318FF", "#6AD2FF", "#EFF4FB"],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#4318FF", "#6AD2FF", "#EFF4FB"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export const pieChartData = [63, 25, 12];

// Total Spent Default

export const barChartData = [
  { 
    value: {
      series: [
        {
          name: 'Actual',
          data: [
            {
              x: '2011',
              y: 1292,
              goals: [
                {
                  name: 'Expected',
                  value: 1400,
                  strokeHeight: 5,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: '2012',
              y: 4432,
              goals: [
                {
                  name: 'Expected',
                  value: 5400,
                  strokeHeight: 5,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: '2013',
              y: 5423,
              goals: [
                {
                  name: 'Expected',
                  value: 5200,
                  strokeHeight: 5,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: '2014',
              y: 6653,
              goals: [
                {
                  name: 'Expected',
                  value: 6500,
                  strokeHeight: 5,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: '2015',
              y: 8133,
              goals: [
                {
                  name: 'Expected',
                  value: 6600,
                  strokeHeight: 13,
                  strokeWidth: 0,
                  strokeLineCap: 'round',
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: '2016',
              y: 7132,
              goals: [
                {
                  name: 'Expected',
                  value: 7500,
                  strokeHeight: 5,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: '2017',
              y: 7332,
              goals: [
                {
                  name: 'Expected',
                  value: 8700,
                  strokeHeight: 5,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: '2018',
              y: 6553,
              goals: [
                {
                  name: 'Expected',
                  value: 7300,
                  strokeHeight: 2,
                  strokeDashArray: 2,
                  strokeColor: '#775DD0'
                }
              ]
            }
          ]
        }
      ],
    }
  }
]

export const barChartOptions = {
  options: {
    colors: ['#00E396'],
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Actual', 'Expected'],
      markers: {
        fillColors: ['#00E396', '#775DD0']
      }
    }
  }
}

export const lineChartDataAbnormalities = [
  {
    name: "barebone 1",
    data: [54, 66, 69, 75, 88, 60, 58, 80, 83]
  },
]

export const lineChartDataAbnormalitiesSintomas = [
  {
    name: "Não liga",
    data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
  },
  {
    name: "Teclado com falha",
    data: [5, 28, 46, 72, 88, 34, 15, 55, 102]
  },
  {
    name: "Imagem/Tela com falha",
    data: [20, 37, 65, 84, 73, 12, 47, 33, 120]
  },
  {
    name: "Não inicializa",
    data: [15, 50, 36, 49, 54, 63, 70, 95, 132]
  }
]

export const lineChartDataAbnormalitiesSkus = [
  {
    name: "3001904",
    data: [148, 91, 69, 62, 49, 51, 35, 41, 10]
  },
  {
    name: "3002030",
    data: [102, 55, 15, 34, 88, 72, 46, 28, 5]
  },
  {
    name: "3001902",
    data: [120, 33, 47, 12, 73, 84, 65, 37, 20]
  },
  {
    name: "3001927",
    data: [132, 95, 70, 63, 54, 49, 36, 50, 15]
  },
  {
    name: "3001925",
    data: [140, 103, 93, 74, 67, 58, 31, 42, 8]
  },
]

export const lineChartOptionsAbnormalities= {
  chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'straight'
  },
  title: {
    text: 'Indice de falha por barebone',
    align: 'left'
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
  }
}

export const lineChartData = [
  {
    label: "Barebone 1",
    value: {
      series: [
        {
          name: "barebone 1",
          type: "line",
          period: "2020",
          data: [54, 66, 69, 75, 88, 60, 58, 80, 83, 74, 61, 49],
        },
        {
          name: "barebone 1",
          type: "line",
          period: "2021",
          data: [42, 57, 63, 72, 85, 56, 48, 78, 81, 70, 55, 38],
        },
        {
          name: "outliers",
          type: "scatter",
          data: [
            {
              x: "MAR/20",
              y: 32
            },
            {
              x: "AGO/20",
              y: 95
            }
          ]
        }
      ],
      skus: [
        {
          name: "SKU 1 - Barebone 1",
          value: {
            series: [
              {
                name: "SKU 1 - Barebone 1",
                type: "line",
                period: "2020",
                data: [42, 57, 63, 72, 85, 56, 48, 78, 81, 70, 55, 38]
              }
            ]
          }
        },
        {
          name: "SKU 2 - Barebone 1",
          value: {
            series: [
              {
                name: "SKU 2 - Barebone 1",
                type: "line",
                period: "2020",
                data: [65, 72, 75, 82, 90, 68, 63, 89, 92, 79, 70, 57]
              },
              {
                name: "SKU 2 - Barebone 1",
                type: "line",
                period: "2021",
                data: [70, 75, 80, 85, 90, 85, 80, 75, 70, 65, 60, 55]
              }
            ]
          }
        },
        {
          name: "SKU 3 - Barebone 1",
          value: {
            series: [
              {
                name: "SKU 3 - Barebone 1",
                type: "line",
                period: "2020",
                data: [47, 61, 67, 76, 87, 59, 54, 79, 82, 72, 65, 50]
              }
            ]
          }
        }
      ],
      segmento: [
        {
          name: "Segmento 1 - Barebone 1",
          value: {
            series: [
              {
                name: "Segmento 1 - Barebone 1 - 2020",
                type: "line",
                period: "2020",
                data: [59, 68, 72, 79, 90, 64, 60, 85, 88, 76, 68, 55]
              },
              {
                name: "Segmento 1 - Barebone 1 - 2021",
                type: "line",
                period: "2021",
                data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120]
              },
              {
                name: "outliers",
                type: "scatter",
                data: [
                  {
                    x: "MAR/20",
                    y: 30
                  },
                  {
                    x: "AGO/20",
                    y: 96
                  }
                ]
              }
            ]
          }
        },
        {
          name: "Segmento 2 - Barebone 1",
          value: {
            series: [
              {
                name: "Segmento 2 - Barebone 1",
                type: "line",
                period: "2020",
                data: [38, 49, 52, 60, 73, 45, 42, 70, 75, 62, 57, 40]
              }
            ]
          }
        },
        {
          name: "Segmento 3 - Barebone 1",
          value: {
            series: [
              {
                name: "Segmento 3 - Barebone 1",
                type: "line",
                period: "2020",
                data: [51, 63, 68, 75, 84, 57, 53, 80, 85, 73, 65, 48]
              }
            ]
          }
        }
      ]
    }
  },
  {
    label: "Barebone 2",
    value: {
      series: [
        {
          name: "barebone 2",
          type: "line",
          period: "2020",
          data: [55, 66, 70, 78, 89, 62, 58, 83, 87, 75, 68, 52]
        },
        {
          name: "outliers",
          type: "scatter",
          data: [
            {
              x: "MAR/20",
              y: 28
            },
            {
              x: "AGO/20",
              y: 90
            }
          ]
        }
      ]
    }
  },
  {
    label: "Barebone 3",
    value: {
      series: [
        {
          name: "barebone 3",
          type: "line",
          period: "2020",
          data: [43, 56, 62, 70, 82, 55, 50, 76, 80, 67, 60, 45]
        },
        {
          name: "outliers",
          type: "scatter",
          data: [
            {
              x: "MAR/20",
              y: 10
            },
            {
              x: "AGO/20",
              y: 90
            }
          ]
        }
      ]
    }
  }
];

export const lineChartOptions = {
  chart: {
    toolbar: {
      show: true,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#7551FF", "#39B8FF"],
  markers: {
    size: [5, 5],
    colors: "white",
    strokeColors: ["#7551FF", "#39B8FF"],
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "datetime",
    categories: ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"],
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
  },
  legend: {
    show: true,
    labels: {
      colors: "#A3AED0",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
  grid: {
    show: true,
    column: {
      color: ["#7551FF", "#39B8FF"],
      opacity: 0.5,
    },
  },
  color: ["#7551FF", "#39B8FF"],
};

export const boxPlotOptions = {
  options: {
    legend: {
    show: true,
    labels: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    responsive: [{
    breakpoint: undefined,
    options: {},
    }],
    chart: {
      height: '100%',
      type: 'boxPlot',
      width: 10,
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: ["#4318FF", "#39B8FF"],  
    markers: {
      size: 5,
      colors: "#FFFFFF",
      strokeColors: ["#7551FF", "#39B8FF"],
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      strokeWidth: 3,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
    },
    tooltip: {
      shared: false,
      intersect: true
    },
    plotOptions: {
      boxPlot: {
        colors: {
          median: "#A3AED0",
          stem: "#A3AED0",
          whisker: "#A3AED0",
          upper: "#7551FF", // Cor da parte superior
          lower: "#4318FF", // Cor da parte inferior
        },
      },
    },
  },
};


export const boxPlotData = [
  { 
    label: "Barebone 1", 
    value: {
      series: [
        {
          name: 'barebone 1',
          type: 'boxPlot',
          data: [
            {
              x: "2020",
              y: [49, 59, 68, 78, 88]
            },
            {
              x: "2021",
              y: [40, 55, 59, 68, 80]
            },
          ]
        },
        {
          name: 'outliers',
          type: 'scatter',
          data: [
            {
              x: "2020",
              y: [32]
            },
            {
              x: "2020",
              y: [95]
            },
          ]
        },
      ],
      skus: [
        {
          name: "SKU 1 - Barebone 1",
          value: {
            series: [
              {
                name: "SKU 1 - Barebone 1",
                type: "boxPlot",
                data: [
                  {
                    x: "2020",
                    y: [38, 55, 63, 72, 85]
                  },
                  {
                    x: "2021",
                    y: [38, 55, 63, 72, 85]
                  }
                ],
              }
            ],
          },          
        },
        {
          name: "SKU 2 - Barebone 1",
          value: {
            series: [
              {
                name: "SKU 2 - Barebone 1",
                type: "boxPlot",
                data: [
                  {
                    x: "2020",
                    y: [57, 68, 73, 81, 92]
                  }
                ],
              },
            ],
          },
        }, 
        {
          name: "SKU 3 - Barebone 1",
          value: {
            series: [
              {
                name: "SKU 3 - Barebone 1",
                type: "boxPlot",
                data: [
                  {
                    x: "2020",
                    y: [50, 63, 70, 76, 87]
                  }
                ],
              },
            ],
          },
        },
      ],
      segmento: [
        {
          name: "Segmento 1 - Barebone 1",
          value: {
            series: [
              {
                name: "Segmento 1 - Barebone 1",
                type: "boxPlot",
                data: [
                  {
                    x: "2020",
                    y: [55, 68, 73, 79, 90]
                  },
                  {
                    x: "2021",
                    y: [55, 68, 73, 79, 90]
                  }
                ],
              },
              {
                name: "outliers",
                type: "scatter",
                data: [
                  {
                    x: "2020",
                    y: [30]
                  },
                  {
                    x: "2020",
                    y: [96]
                  },
                ],
              }
            ],
          },
        },
        {
          name: "Segmento 2 - Barebone 1",
          value: {
            series: [
              {
                name: "Segmento 2 - Barebone 1",
                type: "boxPlot",
                data: [
                  {
                    x: "2020",
                    y: [40, 49, 54, 60, 73]
                  }
                ],
              }
            ]
          },
        }, 
        {
          name: "Segmento 3 - Barebone 1",
          value: {
            series: [
              {
                name: "Segmento 3 - Barebone 1",
                type: "boxPlot",
                data: [
                  {
                    x: "2020",
                    y: [51, 63, 68, 75, 84]
                  }  
                ],
              }
            ]
          },
        },
      ],
    },
  },
  { 
    label: "Barebone 2" ,
    value: {
      series: [
        {
          name: 'barebone 2',
          type: 'boxPlot',
          data: [
            {
              x: "2020",
              y: [52, 66, 70, 78, 89]
            },
          ]
        },
        {
          name: 'outliers',
          type: 'scatter',
          data: [
            {
              x: "2020",
              y: [28]
            },
            {
              x: "2020",
              y: [90]
            },
          ]
        },
      ],
    },
  },
  { 
    label: "Barebone 3" ,
    value: {
      series: [
        {
          name: 'barebone 3',
          type: 'boxPlot',
          data: [
            {
              x: "2020",
              y: [45, 56, 62, 70, 82]
            },
          ]
        },
        {
          name: 'outliers',
          type: 'scatter',
          data: [
            {
              x: "2020",
              y: [10]
            },
            {
              x: "2020",
              y: [90]
            },
          ]
        },
      ],
    },
  },
];

export const bubbleChartData = {
   series: [
    {
      name: "A",
      data: 
  // [220, 32, 60],
  // [398, 15, 34],
  // [682, 54, 45],
  // [973, 25, 24],
  // [1249, 45, 18],
  // [1567, 22, 68],
  // [1865, 42, 16],
  // [2146, 51, 56],
  // [2463, 14, 32],
  // [2795, 42, 69],
  // [3086, 29, 57],
  // [3401, 41, 73],
  // [3690, 28, 33],
  // [4026, 39, 21],
  // [4333, 29, 37],
  // [4633, 53, 45],
  // [4940, 13, 68],
  // [5232, 23, 61],
  // [5543, 39, 29],
  // [5853, 19, 49]
// ]


      [
        {x: "A", y: 1},
        {x: "B", y: 0.6},
        {x: "C", y: 0.5},
        {x: "D", y: 0.2},
      ],
    },
    {
      name: "B",
      data: [
        {x: "A", y: 0.4},
        {x: "B", y: 0.3},
        {x: "C", y: 0.5},
        {x: "D", y: 0},
      ],
    }, 
    {
      name: "C",
      data: [
        {x: "A", y: 0.3},
        {x: "B", y: 0.3},
        {x: "C", y: 0.4},
        {x: "D", y: 0.8},
      ],
    },
    {
      name: "D",
      data: [
        {x: "A", y: 0.5},
        {x: "B", y: 0.3},
        {x: "C", y: 0.2},
        {x: "D", y: 0.7},
      ],
    },
  ],
}

export const bubbleChartOptions = {
  options: {
    legend: {
    show: true,
    labels: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    responsive: [{
    breakpoint: undefined,
    options: {},
    }],
    chart: {
      height: '100%',
      type: 'bubble',
      width: 10,
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: ["#4318FF", "#39B8FF"],  
    // markers: {
    //   size: 5,
    //   colors: "#FFFFFF",
    //   strokeColors: ["#7551FF", "#39B8FF"],
    //   strokeOpacity: 0.9,
    //   strokeDashArray: 0,
    //   strokeWidth: 3,
    //   fillOpacity: 1,
    //   discrete: [],
    //   shape: "circle",
    //   radius: 2,
    //   offsetX: 0,
    //   offsetY: 0,
    //   showNullDataPoints: true,
    // },
    xaxis: {
      categories: ["A", "B", "C", "D"],
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
    },
    tooltip: {
      shared: false,
      intersect: true
    },
  },
};


export const heatmapOptions = {
  options: {
    legend: {
      show: true,
      position: 'bottom',
      labels: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {},
      },
    ],
    chart: {
      toolbar: {
        show: true,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    xaxis: {
      title: {
        text: "Consequêntes",
        style: {
          color: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },

      },
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
    },
    yaxis: {
      show: true,
      title: {
        text: "Antecedentes",
        style: {
          color: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000"],
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0.5,
              color: "#4318FF",
              name: "Baixo",
            },
            {
              from: 0.5,
              to: 0.75,
              color: "#7551FF",
              name: "Médio",
            },
            {
              from: 0.75,
              to: 1,
              color: "#39B8FF",
              name: "Alto",
            },
          ],
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
    },
  },
};

export const heatmapData = [
  {
    label: "Barebone 1",
    value: {
      series: [
        {
          name: "Peça A",
          data: [
            {x: "Peça A", y: 1},
            {x: "Peça B", y: 0.6},
            {x: "Peça C", y: 0.5},
            {x: "Peça D", y: 0.2},
          ],
        },
        {
          name: "Peça B",
          data: [
            {x: "Peça A", y: 0.4},
            {x: "Peça B", y: 0.3},
            {x: "Peça C", y: 0.5},
            {x: "Peça D", y: 0},
          ],
        }, 
        {
          name: "Peça C",
          data: [
            {x: "Peça A", y: 0.3},
            {x: "Peça B", y: 0.3},
            {x: "Peça C", y: 0.4},
            {x: "Peça D", y: 0.8},
          ],
        },
        {
          name: "Peça D",
          data: [
            {x: "Peça A", y: 0.5},
            {x: "Peça B", y: 0.3},
            {x: "Peça C", y: 0.2},
            {x: "Peça D", y: 0.7},
          ],
        },
      ],
      skus: [
        {
          name: "SKU 1 - Barebone 1",
          value: {
            series: [
              {
                name: "Peça A",
                data: [
                  {x: "Peça A", y: 0.8},
                  {x: "Peça B", y: 0.4},
                  {x: "Peça C", y: 0.6},
                  {x: "Peça D", y: 0.3},
                ],
              },
              {
                name: "Peça B",
                data: [
                  {x: "Peça A", y: 0.4},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.5},
                  {x: "Peça D", y: 0},
                ],
              }, 
              {
                name: "Peça C",
                data: [
                  {x: "Peça A", y: 0.6},
                  {x: "Peça B", y: 0.2},
                  {x: "Peça C", y: 0.7},
                  {x: "Peça D", y: 0.1},
                ],
              },
              {
                name: "Peça D",
                data: [
                  {x: "Peça A", y: 0.5},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.2},
                  {x: "Peça D", y: 0.7},
                ],
              },
            ],
          },          
        },
        {
          name: "SKU 2 - Barebone 1",
          value: {
            series: [
              {
                name: "Peça A",
                data: [
                  {x: "Peça A", y: 1},
                  {x: "Peça B", y: 0.6},
                  {x: "Peça C", y: 0.5},
                  {x: "Peça D", y: 0.2},
                ],
              },
              {
                name: "Peça B",
                data: [
                  {x: "Peça A", y: 0.4},
                  {x: "Peça B", y: 0.6},
                  {x: "Peça C", y: 0.5},
                  {x: "Peça D", y: 0.2},
                ],
              }, 
              {
                name: "Peça C",
                data: [
                  {x: "Peça A", y: 0.3},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.4},
                  {x: "Peça D", y: 0.8},
                ],
              },
              {
                name: "Peça D",
                data: [
                  {x: "Peça A", y: 0.7},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.2},
                  {x: "Peça D", y: 0.8},
                ],
              },
            ],
          },
        }, 
        {
          name: "SKU 3 - Barebone 1",
          value: {
            series: [
              {
                name: "Peça A",
                data: [
                  {x: "Peça A", y: 0.5},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.4},
                  {x: "Peça D", y: 0.6},
                ],
              },
              {
                name: "Peça B",
                data: [
                  {x: "Peça A", y: 0.4},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.5},
                  {x: "Peça D", y: 0},
                ],
              }, 
              {
                name: "Peça C",
                data: [
                  {x: "Peça A", y: 0.9},
                  {x: "Peça B", y: 0.7},
                  {x: "Peça C", y: 0.3},
                  {x: "Peça D", y: 0.1},
                ],
              },
              {
                name: "Peça D",
                data: [
                  {x: "Peça A", y: 0.5},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.2},
                  {x: "Peça D", y: 0.7},
                ],
              },
            ],
          },
        },
      ],
      segmento: [
        {
          name: "Segmento 1 - Barebone 1",
          value: {
            series: [
              {
                name: "Peça A",
                data: [
                  {x: "Peça A", y: 0.3},
                  {x: "Peça B", y: 0.5},
                  {x: "Peça C", y: 0.6},
                  {x: "Peça D", y: 0.2},
                ],
              },
              {
                name: "Peça B",
                data: [
                  {x: "Peça A", y: 0.4},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.5},
                  {x: "Peça D", y: 0},
                ],
              }, 
              {
                name: "Peça C",
                data: [
                  {x: "Peça A", y: 0.2},
                  {x: "Peça B", y: 0.4},
                  {x: "Peça C", y: 0.7},
                  {x: "Peça D", y: 0.4},
                ],
              },
              {
                name: "Peça D",
                data: [
                  {x: "Peça A", y: 0.5},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.2},
                  {x: "Peça D", y: 0.7},
                ],
              },
            ],
          },
        },
        {
          name: "Segmento 2 - Barebone 1",
          value: {
            series: [
              {
                name: "Peça A",
                data: [
                  {x: "Peça A", y: 1},
                  {x: "Peça B", y: 0.6},
                  {x: "Peça C", y: 0.5},
                  {x: "Peça D", y: 0.2},
                ],
              },
              {
                name: "Peça B",
                data: [
                  {x: "Peça A", y: 0.4},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.5},
                  {x: "Peça D", y: 0},
                ],
              }, 
              {
                name: "Peça C",
                data: [
                  {x: "Peça A", y: 0.3},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.4},
                  {x: "Peça D", y: 0.8},
                ],
              },
              {
                name: "Peça D",
                data: [
                  {x: "Peça A", y: 0.5},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.2},
                  {x: "Peça D", y: 0.7},
                ],
              },
            ]
          },
        }, 
        {
          name: "Segmento 3 - Barebone 1",
          value: {
            series: [
              {
                name: "Peça A",
                data: [
                  {x: "Peça A", y: 1},
                  {x: "Peça B", y: 0.6},
                  {x: "Peça C", y: 0.5},
                  {x: "Peça D", y: 0.2},
                ],
              },
              {
                name: "Peça B",
                data: [
                  {x: "Peça A", y: 0.6},
                  {x: "Peça B", y: 0.2},
                  {x: "Peça C", y: 0.8},
                  {x: "Peça D", y: 0.3},
                ],
              }, 
              {
                name: "Peça C",
                data: [
                  {x: "Peça A", y: 0.4},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.6},
                  {x: "Peça D", y: 0.7},
                ],
              },
              {
                name: "Peça D",
                data: [
                  {x: "Peça A", y: 0.5},
                  {x: "Peça B", y: 0.3},
                  {x: "Peça C", y: 0.2},
                  {x: "Peça D", y: 0.7},
                ],
              },
            ]
          },
        },
      ],
    },
  },
  { 
    label: "Barebone 2" ,
    value: {
      series: [
        {
          name: "Peça A",
          data: [
            {x: "Peça A", y: 1},
            {x: "Peça B", y: 0.6},
            {x: "Peça C", y: 0.5},
            {x: "Peça D", y: 0.2},
          ],
        },
        {
          name: "Peça B",
          data: [
            {x: "Peça A", y: 0.4},
            {x: "Peça B", y: 0.3},
            {x: "Peça C", y: 0.5},
            {x: "Peça D", y: 0},
          ],
        }, 
        {
          name: "Peça C",
          data: [
            {x: "Peça A", y: 0.3},
            {x: "Peça B", y: 0.3},
            {x: "Peça C", y: 0.4},
            {x: "Peça D", y: 0.8},
          ],
        },
        {
          name: "Peça D",
          data: [
            {x: "Peça A", y: 0.5},
            {x: "Peça B", y: 0.3},
            {x: "Peça C", y: 0.2},
            {x: "Peça D", y: 0.7},
          ],
        },
      ],
    },
  },
  { 
    label: "Barebone 3" ,
    value: {
      series: [
        {
          name: "Peça A",
          data: [
            {x: "Peça A", y: 0.3},
            {x: "Peça B", y: 0.2},
            {x: "Peça C", y: 0.5},
            {x: "Peça D", y: 0.6},
          ],
        },
        {
          name: "Peça B",
          data: [
            {x: "Peça A", y: 0.4},
            {x: "Peça B", y: 0.3},
            {x: "Peça C", y: 0.5},
            {x: "Peça D", y: 0},
          ],
        }, 
        {
          name: "Peça C",
          data: [
            {x: "Peça A", y: 0.7},
            {x: "Peça B", y: 0.4},
            {x: "Peça C", y: 0.3},
            {x: "Peça D", y: 0.8},
          ],
        },
        {
          name: "Peça D",
          data: [
            {x: "Peça A", y: 0.4},
            {x: "Peça B", y: 0.6},
            {x: "Peça C", y: 0.2},
            {x: "Peça D", y: 0.4},
          ],
        },
      ],
    },
  },
];

export const names = [
  'Barebone 1',
  'Barebone 2',
  'Barebone 3',
  'Barebone 4',
  'Barebone 5',
  'Barebone 6',
  'Barebone 7',
  'Barebone 8',
  'Barebone 9',
  'Barebone 10',
  'Barebone 11',
  'Barebone 12',
  'Barebone 13',
  'Barebone 14',
  'Barebone 15',
  'Barebone 16',
  'Barebone 17',
  'Barebone 18',
  'Barebone 19',
  'Barebone 20',
  'Barebone 21',
];