import { Route, Redirect } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { store } from "../../store";
import { getProfile } from "store/auth/actions";

const PrivateRouteComponent = ({ component: Component, getProfile, profile, ...rest }) => {
  // Check the user's authentication status (you should replace this with your authentication logic)
  const isAuthenticated = checkAuthenticationStatus();
  useEffect(() => {
    if (!profile) {
      getProfile();
    }
  });
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/auth" />
      }
    />
  );
};

// Replace these placeholders with your actual authentication and authorization logic
const checkAuthenticationStatus = () => {
  const state = store.getState();
  return state.auth?.access;
};

PrivateRouteComponent.propTypes = {
  getProfile: PropTypes.func,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  profile: state.auth.profile,
});

const PrivateRoute = connect(mapStateToProps, {
  getProfile,
})(PrivateRouteComponent);

export default PrivateRoute;
