import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { getCoOcurrencesSymptomToCause } from "store/co_ocurrences/actions";
import GlobalFilters from "../../components/GlobalFilters";
import SymptomToCauseCoOcurrencesTable from "../../components/SymptomToCauseCoOcurrencesTable";

const SymptomToCauseCoOcurrencesView = function (props) {
  const { getCoOcurrencesSymptomToCause } = props;
  useEffect(() => {
    getCoOcurrencesSymptomToCause();
  }, []);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px">
        <GlobalFilters
          updateData={getCoOcurrencesSymptomToCause}
          showBarebonesFilter
          showSegmentsFilter
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <SymptomToCauseCoOcurrencesTable flexibleHeight />
      </SimpleGrid>
    </Box>
  );
};

SymptomToCauseCoOcurrencesView.propTypes = {
  getCoOcurrencesSymptomToCause: PropTypes.func,
};

const SymptomToCauseCoOcurrences = connect(null, {
  getCoOcurrencesSymptomToCause,
})(SymptomToCauseCoOcurrencesView);

export default SymptomToCauseCoOcurrences;
