import React from "react";
// import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { login } from "store/auth/actions";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function SignInForm({
  loading,
  login
}) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  // const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Informe seu e-mail")
        .email("Formato de email inválido"),
      password: Yup.string()
        .required("Informe sua senha")
        .min(8, "Senha inválida"),
    }),
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      const { email, password } = values;
      login(email, password);
    },
  });
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    formik;
  return (
    <form onSubmit={handleSubmit}>
      <DefaultAuth>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "center" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Preditor
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Confira as análises de anormalidade informando seu e-mail e senha
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl isInvalid={touched.email && errors.email}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
              >
                E-mail<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id="email"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                placeholder="email@positivo.com.br"
                fontWeight="500"
                size="lg"
                mb={touched.email && errors.email ? "0px" : "24px"}
              />
              <FormErrorMessage mb="24px">{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={touched.password && errors.password}>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Senha<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Mínimo de 8 caracteres"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  mb={touched.password && errors.password ? "0px" : "24px"}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage mb="24px">{errors.password}</FormErrorMessage>
              {
                /*
                  <Flex justifyContent="space-between" align="center" mb="24px">
                    <FormControl display="flex" alignItems="center"></FormControl>
                    <NavLink to="/auth/forgot-password">
                      <Text
                        color={textColorBrand}
                        fontSize="sm"
                        w="140px"
                        fontWeight="500"
                      >
                        Esqueci minha senha
                      </Text>
                    </NavLink>
                  </Flex>
                */
              }
              <Button
                isLoading={loading}
                disabled={loading}
                type="submit"
                loadingText="Processando..."
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
              >
                Entrar
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </DefaultAuth>
    </form>
  );
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

const SignIn = connect(mapStateToProps, { login })(SignInForm);

export default SignIn;
