import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { getCoOcurrencesPartToPart } from "store/co_ocurrences/actions";
import GlobalFilters from "../../components/GlobalFilters";
import PartToPartCoOcurrencesTable from "../../components/PartToPartCoOcurrencesTable";

const PartToPartCoOcurrencesView = function (props) {
  const { getCoOcurrencesPartToPart } = props;
  useEffect(() => {
    getCoOcurrencesPartToPart();
  }, []);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px">
        <GlobalFilters
          updateData={getCoOcurrencesPartToPart}
          showBarebonesFilter
          showSegmentsFilter
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <PartToPartCoOcurrencesTable flexibleHeight />
      </SimpleGrid>
    </Box>
  );
};

PartToPartCoOcurrencesView.propTypes = {
  getCoOcurrencesPartToPart: PropTypes.func,
};

const PartToPartCoOcurrences = connect(null, {
  getCoOcurrencesPartToPart,
})(PartToPartCoOcurrencesView);

export default PartToPartCoOcurrences;
