import { Box } from "@chakra-ui/react";
import React, { useEffect  } from "react";
import * as echarts from "echarts";
import { useHistory } from "react-router";

const anormais = ["N14WP6_V2", "S431", "K142", "POS_RIQ470EN", "S532", "N14AP6", "CF40CM", "N14KP6", "N14WP6", "N14ZP7", "POS_RIQ370EB", "N14KP6_PRO", "K142_V2", "POS_RAA320EJ", "N14DP7_V2", "N15WP6_C", "M14KL01", "POS_EIH610EX", "V142R2", "POS_RIB360EC", "POS_RIB360EE"];

const BumpChart = (props) => {
  const { data, maxName, showToolbox } = props;
  const history = useHistory();

  useEffect(() => {
    const chartDom = document.getElementById("main");
    const myChart = echarts.init(chartDom);

    const generateSeriesList = () => {
      const seriesList = [];

      const sortedBarebones = data.reduce((barebones, monthData) => {
        monthData.results.forEach((result) => {
          const existingBarebone = barebones.find(
            (b) => b.barebone === result.barebone && b.segment === result.segment
          );

          if (existingBarebone) {
            existingBarebone.rankings.push(result.ranking);
          } else {
            const isAnormal = anormais.includes(result.barebone);
            barebones.push({
              barebone: result.barebone,
              segment: result.segment,
              rankings: [result.ranking],
              values: [result.value],
              isAnormal: isAnormal,
            });
          }
        });
        return barebones;
      }, []);

      sortedBarebones.forEach((barebone, index) => {
        const seriesData = data.map((monthData) => {
          const result = monthData.results.find(
            (result) => result.barebone === barebone.barebone && result.segment === barebone.segment
          );
          return result ? result.ranking : null;
        });

        const series = {
          name: barebone.barebone,
          symbolSize: 20,
          type: "line",
          smooth: true,
          emphasis: {
            focus: "series",
          },
          endLabel: {
            show: true,
            formatter: "{a}",
            distance: 20,
          },
          lineStyle: {
            width: 4,
          },
          itemStyle: {
            color: barebone.isAnormal ? 'black' : 'auto',
          },
          data: seriesData,
        };

        seriesList.push(series);
      });
      return seriesList;
    };

    const option = {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          const { barebone, segment, value } = data[params.dataIndex].results.find(
            (result) => result.ranking === params.value
          );
          return `${segment} - ${barebone} <br/> ${value}`;
        }
      },
      grid: {
        left: 30,
        right: 110,
        bottom: 30,
        containLabel: true,
      },
      toolbox: {
        show: showToolbox,
        feature: {
          saveAsImage: {
            title: "Salvar como imagem",
            name: "bump_chart",
            show: true,
          },
        },
      },
      xAxis: {
        type: "category",
        splitLine: {
          show: true,
        },
        axisLabel: {
          margin: 30,
          fontSize: 16,
        },
        boundaryGap: false,
        data: data.map((monthData) => monthData.month),
        position: "top",
      },
      yAxis: {
        type: "value",
        axisLabel: {
          margin: 30,
          fontSize: 16,
          formatter: "#{value}",
        },
        inverse: true,
        interval: 1,
        min: 1,
        max: maxName,
      },
      series: generateSeriesList(),
    };

    myChart.on('click', (params) => {
      if (params.componentType === 'series') {
        const { barebone } = data[params.dataIndex].results.find(
          (result) => result.ranking === params.value
        );
        history.push({
          pathname: '/admin/productDetails',
          state: { barebone: barebone }
        });
      }
    });

    myChart.setOption(option);

    // Cleanup when unmounting
    return () => {
      myChart.dispose();
    };
  }, [data, history]);

    return <Box id="main" width="100%" height="500px"></Box>
};

export default BumpChart;