import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import OutliersChart from "views/admin/components/OutliersChart";
import GlobalFilters from "../components/GlobalFilters";
import { getDashboardData } from "store/auth/actions";
import PartToPartCoOcurrencesTable from "../components/PartToPartCoOcurrencesTable";
import SymptomToPartCoOcurrencesTable from "../components/SymptomToPartCoOcurrencesTable";
import CauseToPartCoOcurrencesTable from "../components/CauseToPartCoOcurrencesTable";
import SymptomToCauseCoOcurrencesTable from "../components/SymptomToCauseCoOcurrencesTable";
import RankingChart from "../components/RankingChart";

const DashboardMainComponent = function(props) {
  const { getDashboardData } = props;
  useEffect(() => {
    getDashboardData()
  }, [getDashboardData]);
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px" columns={{ base: 1, md: 2, xl: 3 }}>
        <GlobalFilters updateData={getDashboardData} />
      </SimpleGrid>
      <SimpleGrid mt="10px" mb="20px" columns={{ base: 1 }}>
        <RankingChart showFilterButton />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <OutliersChart showFilterButton />
        <PartToPartCoOcurrencesTable showFilterButton />
        <SymptomToPartCoOcurrencesTable showFilterButton />
        <CauseToPartCoOcurrencesTable showFilterButton />
        <SymptomToCauseCoOcurrencesTable showFilterButton />
      </SimpleGrid>
    </Box>
  );
}

const DashboardMain = connect(null, { getDashboardData })(DashboardMainComponent);

export default DashboardMain;
