import { combineReducers } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import authReducer from "store/auth/slice";
import filtersReducer from "store/filters/slice";
import outliersReducer from "store/outliers/slice";
import coOcurrencesReducer from "store/co_ocurrences/slice";
import rankingReducer from "store/ranking/slice";
import blackListReducer from "store/blacklist/slice";

export const history = createBrowserHistory();

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    filters: filtersReducer,
    outliers: outliersReducer,
    coOcurrences: coOcurrencesReducer,
    ranking: rankingReducer,
    blackList: blackListReducer,
  });

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(thunk)
      .concat(routerMiddleware(history)),
});
