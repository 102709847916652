import axios from "axios";
import _ from "lodash";
import { setLoading, getRankingSuccessful, getRankingError } from "./slice";
import { toast } from "react-toastify";
import { API_ROUTE_RANKING } from "api/routes";

export const getRanking = () => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { startDate, rankingSize, endDate, barebones, segments } = getState().filters;
  const selectedBarebones = _.map(barebones.selected, (item) => item.value).join(",");
  const selectedSegments = _.map(segments.selected, (item) => item.value).join(",");

  const formattedStartDate = startDate?.split("/").reverse().join("-");
  const formattedEndDate = endDate?.split("/").reverse().join("-");
  const formattedRankingSize = rankingSize ? rankingSize : 10;

  axios
    .get(API_ROUTE_RANKING, {
      params: {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        barebones: selectedBarebones,
        segments: selectedSegments,
        ranking_size: formattedRankingSize,
      },
    })
    .then((response) => {
      dispatch(getRankingSuccessful(response?.data));
    })
    .catch((error) => {
      dispatch(getRankingError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações do Ranking de anormalidades. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
