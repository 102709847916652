import axios from "axios";
import _ from "lodash";
import { setLoading, getOutliersSuccessful, getOutliersError } from "./slice";
import { toast } from "react-toastify";
import { API_ROUTE_OUTLIERS } from "api/routes";

export const getOutliers = () => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { startDate, endDate, barebones, segments } = getState().filters;
  const selectedBarebones = _.map(
    barebones.selected,
    (item) => item.value
  ).join(",");
  const selectedSegments = _.map(segments.selected, (item) => item.value).join(
    ","
  );
  axios
    .get(API_ROUTE_OUTLIERS, {
      params: {
        start_date: startDate?.split("/").reverse().join("-"),
        end_date: endDate?.split("/").reverse().join("-"),
        barebones: selectedBarebones,
        segments: selectedSegments,
      },
    })
    .then((response) => {
      dispatch(getOutliersSuccessful(response?.data));
    })
    .catch((error) => {
      dispatch(getOutliersError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de outliers. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
