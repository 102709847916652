import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  items: []
};

const outliersSlice = createSlice({
  name: "outliers",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    getOutliersSuccessful: (state, action) => {
      state.items = action.payload;
    },
    getOutliersError: (state) => {
      state.items = {};
    }
  },
});

export const {
  setLoading,
  getOutliersSuccessful,
  getOutliersError
} = outliersSlice.actions;

export default outliersSlice.reducer;
