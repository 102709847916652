import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { getRanking } from "store/ranking/actions";
import GlobalFilters from "../components/GlobalFilters";
import RankingChart from "../components/RankingChart";
import { useColorModeValue } from "@chakra-ui/color-mode";

const RankingView = function (props) {
  const { getRanking } = props;

  useEffect(() => {
    getRanking();
  }, []);

  const subtitleColor = useColorModeValue("secondaryGray.500", "white");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px">
        <GlobalFilters
          updateData={getRanking}
          showBarebonesFilter
          showSegmentsFilter
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <RankingChart flexibleHeight showToolbox={true}/>
      </SimpleGrid>
    </Box>
  );
};

RankingView.propTypes = {
  getRanking: PropTypes.func,
};

const Ranking = connect(null, {
  getRanking,
})(RankingView);

export default Ranking;
