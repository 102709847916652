import axios from "axios";
import { store } from "../store";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "./storageTypes";
import { updateTokens, logout } from "store/auth/actions";
import { API_ROUTE_REFRESH_TOKEN } from "./routes";

export const initApp = () => {
  // Set a global base URL for Axios
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL; // Replace with your API's base URL
  axios.defaults.timeout = 60000; // Adjust the timeout as needed

  // Axios interceptor to handle token refreshing
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        if (error.request?.responseURL?.includes(API_ROUTE_REFRESH_TOKEN)) {
          store.dispatch(logout())
        } else {
          try {
            const { data } = await refreshAccessToken();
            localStorage.getItem(ACCESS_TOKEN, data.access);
            store.dispatch(
              updateTokens({
                access: data.access,
                refresh: localStorage.getItem(REFRESH_TOKEN),
              })
            );
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${data.access}`;
            // Retry the original request with the new access token
            error.config.headers["Authorization"] = `Bearer ${data.access}`;
            return axios(error.config);
          } catch (refreshError) {
            // Handle token refresh failure, e.g., log out or redirect to the login page
            console.error("Token refresh failed:", refreshError);
            // throw refreshError; // Uncomment this line to re-throw the refresh error
          }
        }
      }
      return Promise.reject(error);
    }
  );

  const token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {
    store.dispatch(
      updateTokens({
        access: token,
        refresh: localStorage.getItem(REFRESH_TOKEN),
      })
    );
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

const refreshAccessToken = () => {
  return axios.post(API_ROUTE_REFRESH_TOKEN, {
    refresh: localStorage.getItem(REFRESH_TOKEN),
  });
};
