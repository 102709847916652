import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  partToPart: {
    loading: false,
    items: []
  },
  symptomToCause: {
    loading: false,
    items: []
  },
  causeToPart: {
    loading: false,
    items: []
  },
  symptomToPart: {
    loading: false,
    items: []
  },
};

const coOcurrencesSlice = createSlice({
  name: "coOcurrences",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.partToPart.loading = action.payload;
    },
    getCoOcurrencesPartToPartSuccessful: (state, action) => {
      state.partToPart.items = action.payload;
    },
    getCoOcurrencesPartToPartError: (state) => {
      state.partToPart.items = [];
    },
    setLoadingSymptomToCause: (state, action) => {
      state.symptomToCause.loading = action.payload;
    },
    getCoOcurrencesSymptomToCauseSuccessful: (state, action) => {
      state.symptomToCause.items = action.payload;
    },
    getCoOcurrencesSymptomToCauseError: (state) => {
      state.symptomToCause.items = [];
    },
    setLoadingCauseToPart: (state, action) => {
      state.causeToPart.loading = action.payload;
    },
    getCoOcurrencesCauseToPartSuccessful: (state, action) => {
      state.causeToPart.items = action.payload;
    },
    getCoOcurrencesCauseToPartError: (state) => {
      state.causeToPart.items = [];
    },
    setLoadingSymptomToPart: (state, action) => {
      state.symptomToPart.loading = action.payload;
    },
    getCoOcurrencesSymptomToPartSuccessful: (state, action) => {
      state.symptomToPart.items = action.payload;
    },
    getCoOcurrencesSymptomToPartError: (state) => {
      state.symptomToPart.items = [];
    },
  },
});

export const {
  setLoading,
  getCoOcurrencesPartToPartSuccessful,
  getCoOcurrencesPartToPartError,
  setLoadingSymptomToCause,
  getCoOcurrencesSymptomToCauseSuccessful,
  getCoOcurrencesSymptomToCauseError,
  setLoadingCauseToPart,
  getCoOcurrencesCauseToPartSuccessful,
  getCoOcurrencesCauseToPartError,
  setLoadingSymptomToPart,
  getCoOcurrencesSymptomToPartSuccessful,
  getCoOcurrencesSymptomToPartError,
} = coOcurrencesSlice.actions;

export default coOcurrencesSlice.reducer;
