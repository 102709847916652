import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  items: []
};

const rankingSlice = createSlice({
  name: "ranking",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    getRankingSuccessful: (state, action) => {
      state.items = action.payload;
    },
    getRankingError: (state) => {
      state.items = [];
    }
  },
});

export const {
  setLoading,
  getRankingSuccessful,
  getRankingError
} = rankingSlice.actions;

export default rankingSlice.reducer;
