import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/color-mode";

const ProductDetails = function ({location, ...props}) {
   const barebone = location.state ? location.state.barebone : null;
  const titleColor = useColorModeValue("secondaryGray.900", "white");

  console.log("ProductDetails props", props);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px">
        <Text
          pt="5px"
          me="auto"
          color={titleColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Detalhes do Produto: {barebone}
        </Text>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        
      </SimpleGrid>
    </Box>
  );
};

export default ProductDetails;
