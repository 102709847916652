import { useState, createContext } from "react";
import { boxPlotData, lineChartData, heatmapData } from "variables/charts";
import tableDataColumns from "./tableDataColumns.json";
import dayjs from 'dayjs';
import { names } from "variables/charts"

export const VisualizeChartsContext = createContext({});

export const VisualizeChartsProvider = ({ children }) => {

    const pagesQuant = Math.ceil(names.length / 10)

    const paginatedNames = [];

    for (let page = 0; page < pagesQuant; page++) {
        const startIndex = page * 10;
        const endIndex = startIndex + 10;
        const pageNames = names.slice(startIndex, endIndex);
        paginatedNames.push(pageNames);
    }

    // Barebone
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [period, setPeriod] = useState();

    const bareboneOptionsBoxPlot = boxPlotData;
    const bareboneOptionsLine = lineChartData;
    const bareboneOptionsHeatmap = heatmapData;
    const bareboneOptionsTable = tableDataColumns;

    const getYear = boxPlotData.map(item => item.label === "Barebone 1" ? item.value.series: []).flat().find(item => item.name === "barebone 1");

    const defaultYear = getYear.data[0].x;
    const defaultPeriod = [`01/01/${defaultYear}`, `31/12/${defaultYear}`];

    if (!period) {
        setPeriod(defaultPeriod);
    }

    // Periodo
    const handlePeriod = (value) => {
        if (value && value.length === 2) {
            setPeriod([dayjs(value[0]).format('DD/MM/YYYY'), dayjs(value[1]).format('DD/MM/YYYY')]);
        } else {
            setPeriod([]);
        }
    };

    // Segmento
    const segmentoOptions = boxPlotData.flatMap((item) =>
        item.value && item.value.segmento
            ? item.value.segmento.map((segmento) => ({ label: segmento.name, value: segmento.value }))
        : []
    );

    const segmentoOptionsLine = lineChartData.flatMap((item) =>
        item.value && item.value.segmento
            ? item.value.segmento.map((segmento) => ({ label: segmento.name, value: segmento.value })) 
        : []
    );

    const segmentoOptionsHeatmap = heatmapData.flatMap((item) =>
        item.value && item.value.segmento
            ? item.value.segmento.map((segmento) => ({ label: segmento.name, value: segmento.value }))
        : []
    );

    const segmentoOptionsTable = tableDataColumns.flatMap((item) =>
        item && item.segmento
            ? item.segmento.map((segmento) => ({ label: segmento.name, value: segmento.data }))
        : []
    );

    // SKU
    const skuOptions = boxPlotData.flatMap((item) =>
        item.value && item.value.skus
            ? item.value.skus.map((skus) => ({ label: skus.name, value: skus.value }))
        : []
    );

    const skuOptionsLine = lineChartData.flatMap((item) =>
        item.value && item.value.skus
            ? item.value.skus.map((skus) => ({ label: skus.name, value: skus.value }))
        : []
    );

    const skuOptionsHeatmap = heatmapData.flatMap((item) =>
        item.value && item.value.skus
            ? item.value.skus.map((skus) => ({ label: skus.name, value: skus.value }))
        : []
    );

    const skuOptionsTable = tableDataColumns.flatMap((item) =>
        item && item.skus
            ? item.skus.map((skus) => ({ label: skus.name, value: skus.data }))
        : []
    );

    const updateSelectedOptions = (newSelectedOptions) => {
        setSelectedOptions(newSelectedOptions);
    };

    return (

        <VisualizeChartsContext.Provider value={{ 
            selectedOptions, 
            bareboneOptionsBoxPlot, 
            bareboneOptionsLine, 
            bareboneOptionsHeatmap, 
            bareboneOptionsTable, 
            segmentoOptions, 
            skuOptions, 
            segmentoOptionsLine, 
            segmentoOptionsHeatmap, 
            segmentoOptionsTable, 
            skuOptionsLine, 
            skuOptionsHeatmap, 
            skuOptionsTable,
            updateSelectedOptions,
            handlePeriod,
            period,
            defaultPeriod,
            pagesQuant,
            paginatedNames
        }}
        >
            {children}
        </VisualizeChartsContext.Provider>
    )
}
