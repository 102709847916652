import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  loading: {
    barebones: false,
    segments: false
  },
  startDate: process.env.REACT_APP_DEFAULT_START_DATE,
  endDate: process.env.REACT_APP_DEFAULT_END_DATE,
  barebones: {
    all: [],
    selected: []
  },
  segments: {
    all: [],
    selected: []
  },
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setLoadingBarebones: (state, action) => {
      state.loading.barebones = action.payload;
    },
    setLoadingSegments: (state, action) => {
      state.loading.segments = action.payload;
    },
    getBarebonesSuccessful: (state, action) => {
      state.barebones.all = _.map(action.payload, (item) => ({ value: item, label: item }));
    },
    getBarebonesError: (state) => {
      state.barebones.all = [];
    },
    getSegmentsSuccessful: (state, action) => {
      state.segments.all = _.map(action.payload, (item) => ({ value: item, label: item }));
    },
    getSegmentsError: (state) => {
      state.segments.all = [];
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload
    },
    setSelectedBarebones: (state, action) => {
      state.barebones.selected = action.payload
    },
    setSelectedSegments: (state, action) => {
      state.segments.selected = action.payload
    },
  },
});

export const {
  setLoadingBarebones,
  setLoadingSegments,
  getBarebonesSuccessful,
  getBarebonesError,
  getSegmentsSuccessful,
  getSegmentsError,
  setStartDate,
  setEndDate,
  setSelectedBarebones,
  setSelectedSegments
} = filtersSlice.actions;

export default filtersSlice.reducer;
