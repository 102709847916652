import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router";
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import { MdTune } from "react-icons/md";
import AppSpinner from "components/spinner/AppSpinner";


const lineChartOptions = {
  chart: {
    toolbar: {
      show: true,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#7551FF", "#39B8FF"],
  markers: {
    size: [5, 5],
    colors: "white",
    strokeColors: ["#7551FF", "#39B8FF"],
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
  },
  tooltip: {
    theme: "dark",
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "datetime",
    title: {
      text: "Período",
      style: {
        fontSize: "14px",
      },
    },
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    title: {
      text: "Quantidade de chamados",
      style: {
        fontSize: "14px",
      },
    },
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
  },
  legend: {
    show: true,
    labels: {
      colors: "#A3AED0",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
  grid: {
    show: true,
    column: {
      color: ["#7551FF", "#39B8FF"],
      opacity: 0.5,
    },
  },
};

const OutliersChartComponent = function (props) {
  const { flexibleHeight, showFilterButton, loading, items, startDate, endDate } = props;
  const history = useHistory();

  const titleColor = useColorModeValue("secondaryGray.900", "white");
  const subtitleColor = useColorModeValue("secondaryGray.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  // console.log(items);

  const data = _.map(items, "N_CHAMADOS");
  const categories = _.map(items, "ENCERRADO");
  const points = _.map(
    _.filter(items, (item) => item.outlier),
    (outlier) => ({
      x: new Date(outlier.ENCERRADO).getTime(),
      y: outlier.N_CHAMADOS,
      marker: {
        size: 8,
        fillColor: "#fff",
        strokeColor: "red",
        radius: 2,
        cssClass: "apexcharts-custom-class",
      },
      label: {
        borderColor: "#FF4560",
        offsetY: 0,
        style: {
          color: "#fff",
          background: "#FF4560",
        },
        text: "Outlier",
      },
    })
  );

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      h={flexibleHeight ? undefined : "375px"}
      mb="10px"
    >
      <Flex justify="space-between" ps="0px" pe="5px" pt="0px" mb="10px">
        <Flex align="center" w="100%">
          <Text
            me="auto"
            color={titleColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Outliers
          </Text>
          {showFilterButton && (
            <Button
              ms="auto"
              align="center"
              justifyContent="center"
              bg={bgButton}
              w="37px"
              h="37px"
              lineHeight="100%"
              borderRadius="10px"
              onClick={() => history.push("/admin/outliers")}
            >
              <Icon as={MdTune} color="brand.500" w="24px" h="24px" />
            </Button>
          )}
        </Flex>
      </Flex>
      {!loading && (
        <Flex align="center" w="100%" mb="15px">
          <Text
            me="auto"
            color={subtitleColor}
            fontSize="14px"
            lineHeight="100%"
          >
            {points.length
              ? `Período de ${startDate} à ${endDate}`
              : `Não existem outliers para o período de ${startDate} à ${endDate}`}
          </Text>
        </Flex>
      )}
      <Flex w="100%" flexDirection="column">
        <Box minH="260px" minW="75%" mt="auto">
          {loading ? (
            <AppSpinner />
          ) : (
            <LineChart
              chartData={[
                {
                  name: "Quantidade de chamados",
                  data,
                },
              ]}
              chartOptions={{
                ...lineChartOptions,
                xaxis: {
                  ...lineChartOptions.xaxis,
                  categories,
                },
                annotations: { points },
              }}
            />
          )}
        </Box>
      </Flex>
    </Card>
  );
};

OutliersChartComponent.propTypes = {
  showFilterButton: PropTypes.bool,
  flexibleHeight: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  loading: PropTypes.bool,
  items: PropTypes.array,
};

const mapStateToProps = (state) => ({
  startDate: state.filters.startDate,
  endDate: state.filters.endDate,
  loading: state.outliers.loading,
  items: state.outliers.items,
});

const OutliersChart = connect(mapStateToProps, null)(OutliersChartComponent);

export default OutliersChart;
