import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "components/card/Card";
import AppSpinner from "components/spinner/AppSpinner";
import SegmentsFilter from "components/menu/BarebonesFilter";
import { addToBlacklist, getBlacklist, removeFromBlacklist} from "store/blacklist/actions";
import BarebonesFilter from "components/menu/BarebonesFilter";
//import { addToBlacklist, getBlacklist } from "store/blacklist/actions";
import { BsTrash } from "react-icons/bs";
import { setSelectedBarebones } from "store/filters/slice";
import { toast } from "react-toastify";
import {
  Paginator,
  Container,
  Previous,
  Next,
  PageGroup,
} from "chakra-paginator";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
const columns = [
  {
    Header: "Barebone",
    accessor: "barebone",
  },
  {
    Header: "Segmento",
    accessor: "segment",
  },
  {
    Header: "Incluído em",
    accessor: "created_at",
  },
  {
    Header: "Ações",
    accessor: "actions",
  },
];

const BlackListTableComponent = function (props) {
  const { flexibleHeight, blacklist: items } = props;

  const [showAddPopup, setShowAddPopup] = useState(false);

  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [bareboneToRemove, setBareboneToRemove] = useState({   
    id:"", 
    actions: null,
    barebone:"",
    created_at: "",
    segment:""
  });
  
  const titleColor = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgSecondaryButton = useColorModeValue(
    "secondaryGray.300",
    "whiteAlpha.100"
  );
  useEffect(() => {
    props.getBlacklist({
      page: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data: props.blacklist,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 10;
  initialState.manualPagination = true;

  return (
    <Flex direction="column" gap="15px">
      
      {showAddPopup && (
        <Flex justifyContent="end" gap="10px">
          
          <BarebonesFilter
            containerStyle={{
              margin: "unset",
              marginTop: "auto",
              marginBottom: "auto",
            }}
            updateData={() => {}}
          />
          <Button
            variant="brand"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            onClick={() => {
              if (props.selectedItems.length === 0) {
                return toast.error(
                  "Selecione ao menos um barebone para adicionar à blacklist"
                );
              }
              const selectedItems = props.selectedItems.map(
                (item) => item.value
              );
              props.addToBlacklist(selectedItems);
              setShowAddPopup(false);
              props.setSelectedBarebones([]);
            }}
          >
            Salvar
          </Button>
          <Button
            bg={bgSecondaryButton}
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            onClick={() => {
              props.setSelectedBarebones([]);
              setShowAddPopup(false);
            }}
          >
            Cancelar
          </Button>
        </Flex>
      )}

      {!showAddPopup && (
        <Button
          ms="auto"
          align="center"
          justifyContent="center"
          variant="brand"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          onClick={() => {
            setShowAddPopup(true);
          }}
        >
          Adicionar barebone
        </Button>
      )}
      <Card
        align="center"
        direction="column"
        w="100%"
        h={flexibleHeight ? undefined : "375px"}
        mb="10px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex justify="space-between" ps="0px" pe="5px" pt="0px" mb="10px">
          <Flex align="center" w="100%">
            <Text
              me="auto"
              mb="10px"
              color={titleColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Blacklist de Barebones
            </Text>


          </Flex>
        </Flex>
        
        {props.loading ? (
          <AppSpinner />
        ) : items.length > 0 ? (
          <Flex direction="column">
            <Table {...getTableProps()} variant="simple" color="gray.500">
              <Thead>
                {headerGroups.map((headerGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <Th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        pe="20px"
                        py="5px"
                        key={index}
                        borderColor={borderColor}
                      >
                        <Flex
                          justify="center"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {column.render("Header")}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let data = undefined;
                        if (cell.column.id === "barebone") {
                          data = (
                            <Flex align="center" justify="center">
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {cell.value}
                              </Text>
                            </Flex>
                          );
                        } else if (cell.column.id === "created_at") {
                          data = (
                            <Flex align="center" justify="center">
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {new Date(cell.value).toLocaleString()}
                              </Text>
                            </Flex>
                          );
                        } else if (cell.column.id === "actions") {
                          data = (
                            <Flex align="center" justify="center">
                              <Button
                              onClick={() => {
                                console.log()
                                setBareboneToRemove({...cell.row.values, 
                                  id: cell.row.original.id});
                                setShowRemovePopup(true);
                              }}
                              >
                                <BsTrash />
                              </Button>
                            </Flex>
                          );
                        } else if (cell.column.id === "segment") {
                          data = (
                            <Flex align="center" justify="center">
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {cell.value}
                              </Text>
                            </Flex>
                          );
                        }
                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <ChakraProvider>
              <Paginator
                pagesQuantity={props.pageCount}
                currentPage={props.page}
                onPageChange={(nextPage) => {
                  props.getBlacklist({ page: nextPage });
                }}
                activeStyles={{
                  w: "30px",
                  h: "30px",
                  bg: "blue.500",
                  color: "white",
                  borderRadius: "15px",
                }}
              >
                <Flex justify="end" gap="5px">
                  <Previous bg={bgSecondaryButton}>
                    <IoIosArrowDropleft color={textColor} />
                  </Previous>
                  <PageGroup align="center" />
                  <Next bg={bgSecondaryButton}>
                    <IoIosArrowDropright color={textColor} />
                  </Next>
                </Flex>
              </Paginator>
            </ChakraProvider>
          </Flex>
        ) : (
          <Flex justify="center" align="center" w="100%" h="100%" mb="10px">
            <Text color={titleColor} fontSize="16px" lineHeight="100%">
              Nenhum barebone adicionado
            </Text>
          </Flex>
        )}
      </Card>

      <Modal isOpen={showRemovePopup} onClose={() => setShowRemovePopup(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remover barebone</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Tem certeza que deseja remover o barebone {bareboneToRemove.barebone} da blacklist?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => {
               props.removeFromBlacklist(bareboneToRemove.id)
               setShowRemovePopup(false);
            }}>
              Remover
            </Button>
            <Button variant='ghost' onClick={() => setShowRemovePopup(false)}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

BlackListTableComponent.propTypes = {
  showFilterButton: PropTypes.bool,
  flexibleHeight: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  loading: PropTypes.bool,
  items: PropTypes.array,
  blackList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  startDate: state.filters.startDate,
  endDate: state.filters.endDate,
  ...state.coOcurrences.partToPart,
  blacklist: state.blackList.blacklist,
  selectedItems: state.filters.barebones.selected,
  loading: state.blackList.loading,
  page: state.blackList.page,
  pageCount: state.blackList.pageCount,
});

const BlackListTable = connect(mapStateToProps, {
  addToBlacklist,
  removeFromBlacklist,
  getBlacklist,
  setSelectedBarebones,
})(BlackListTableComponent);

export default BlackListTable;