import React from "react";
import { Spinner } from "@chakra-ui/react";

const AppSpinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spinner size="xl" color="#11047A" />
  </div>
);

export default AppSpinner;
