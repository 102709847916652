import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "api/storageTypes";

const initialState = {
  loading: false,
  access: undefined,
  refresh: undefined,
  profile: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    loginSuccessful: (state, action) => {
      state.access = action.payload?.access;
      localStorage.setItem(ACCESS_TOKEN, state.access);
      axios.defaults.headers.common['Authorization'] = `Bearer ${state.access}`;
      state.refresh = action.payload?.refresh;
      localStorage.setItem(REFRESH_TOKEN, state.refresh);
    },
    loginError: (state) => {
      state.access = undefined;
      state.refresh = undefined;
    },
    deleteUserData: (state) => {
      state.access = undefined;
      localStorage.removeItem(ACCESS_TOKEN);
      state.refresh = undefined;
      localStorage.removeItem(REFRESH_TOKEN);
      state.profile = undefined;
    },
    updateProfileData: (state, action) => {
      state.profile = action.payload;
    },
    setTokens: (state, action) => {
      state.access = action.payload?.access;
      state.refresh = action.payload?.refresh;
    },
  },
});

export const {
  setLoading,
  loginSuccessful,
  loginError,
  deleteUserData,
  updateProfileData,
  setTokens,
} = authSlice.actions;

export default authSlice.reducer;
