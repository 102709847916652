import { setBlacklist, setLoading } from "./slice";
import axios from "axios";
import { API_ROUTE_BLACKLIST } from "api/routes";
import { toast } from "react-toastify";

export const addToBlacklist = (payload) => (dispatch) => {
    dispatch(setLoading(true));
    axios.post(API_ROUTE_BLACKLIST, {
        barebones: payload
    }).then((data) => {
        const { data: payload } = data;
        toast.success(payload.message);
        axios.get(API_ROUTE_BLACKLIST).then((data) => {
            const { data: payload } = data;
            dispatch(setBlacklist(payload));
        })
    })
    .catch((error) => {
        const { response } = error;
        toast.error( response.data.message);
    })
    .finally(() => {
        dispatch(setLoading(false));
    });
}

export const getBlacklist = (payload) => (dispatch) => {
    const {page} = payload;
    dispatch(setLoading(true));
    axios.get(API_ROUTE_BLACKLIST, {
        params: {
            page
        }
    })
    .then((data) => {
        const { data: payload } = data;
        dispatch(setBlacklist(payload));
    })
    .catch((error) => {
        const { response } = error;
        toast.error(response.data.message);
    })
    .finally(() => {
        dispatch(setLoading(false));
    });
}

export const removeFromBlacklist = (payload) => (dispatch) => {
    const id = payload;
    console.log(payload);
    dispatch(setLoading(true));
    axios.delete(API_ROUTE_BLACKLIST, {
        params: {
            bareboneId: id
        }
    })
    .then((data) => {
        const { data: payload } = data;
        console.log(payload);
        toast.success(payload.message);
        axios.get(API_ROUTE_BLACKLIST).then((data) => {
            const { data: payload } = data;
            dispatch(setBlacklist(payload));
        })
        //dispatch(setBlacklist(payload));
    })
    .catch((error) => {
        const { response } = error;
        toast.error(response.data.message);
    })
    .finally(() => {
        dispatch(setLoading(false));
    });
}