import axios from "axios";
import {
  setLoading,
  loginSuccessful,
  loginError,
  deleteUserData,
  updateProfileData,
  setTokens,
} from "./slice";
import { toast } from "react-toastify";
import { go } from "connected-react-router";
import { API_ROUTE_TOKEN } from "api/routes";
import { API_ROUTE_PROFILE } from "api/routes";
import { getOutliers } from "store/outliers/actions";
import { getCoOcurrencesPartToPart, getCoOcurrencesSymptomToPart, getCoOcurrencesCauseToPart, getCoOcurrencesSymptomToCause } from "store/co_ocurrences/actions";
import { getRanking } from "store/ranking/actions";

export const login = (email, password) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(API_ROUTE_TOKEN, { email, password })
    .then((response) => {
      dispatch(loginSuccessful(response?.data));
      dispatch(go("/admin/default"));
    })
    .catch((error) => {
      dispatch(loginError(error.response?.data));
      toast.error(error.response?.data?.detail);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const logout = () => (dispatch) => {
  dispatch(deleteUserData());
  dispatch(go("/auth/sign-in/default"));
};

export const getProfile = () => (dispatch) => {
  axios
    .get(API_ROUTE_PROFILE)
    .then((response) => {
      dispatch(updateProfileData(response?.data));
    })
    .catch((error) => {
      dispatch(updateProfileData(undefined));
    });
};

export const updateTokens = (payload) => (dispatch) => {
  dispatch(setTokens(payload));
};

export const getDashboardData = () => (dispatch) => {
  dispatch(getOutliers());
  dispatch(getCoOcurrencesPartToPart());
  dispatch(getCoOcurrencesSymptomToPart());
  dispatch(getCoOcurrencesCauseToPart());
  dispatch(getCoOcurrencesSymptomToCause());
  dispatch(getRanking());
};
