// Auth
export const API_ROUTE_TOKEN = "auth/token/";
export const API_ROUTE_REFRESH_TOKEN = "auth/token/refresh/";
export const API_ROUTE_PROFILE = "users/me/";

// Filters
export const API_ROUTE_FILTERS_BAREBONES = "filters/barebones/";
export const API_ROUTE_FILTERS_SEGMENTS = "filters/segments/";

// Outliers
export const API_ROUTE_OUTLIERS = "outliers/";

// Co-ocurrences
export const API_ROUTE_CO_OCURRENCES_PART_TO_PART = "co_ocurrences/part_to_part/";
export const API_ROUTE_CO_OCURRENCES_SYMPTOM_TO_PART = "co_ocurrences/symptom_to_part/";
export const API_ROUTE_CO_OCURRENCES_CAUSE_TO_PART = "co_ocurrences/cause_to_part/";
export const API_ROUTE_CO_OCURRENCES_SYMPTOM_TO_CAUSE = "co_ocurrences/symptom_to_cause/";

// Ranking
export const API_ROUTE_RANKING = "ranking/";

// blacklists
export const API_ROUTE_BLACKLIST = "blacklist/";