import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "components/card/Card.js";
import { MdTune } from "react-icons/md";
import AppSpinner from "components/spinner/AppSpinner";
import BumpChart from "components/charts/BumpChart";
import { useHistory } from "react-router-dom";

const RankingChartComponent = function (props) {
  const { flexibleHeight, showFilterButton, loading, items, startDate, endDate, showToolbox, selectedItens, selectedItems } = props;
  const history = useHistory();

  const titleColor = useColorModeValue("secondaryGray.900", "white");
  const subtitleColor = useColorModeValue("secondaryGray.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

const filteredItems =
  selectedItens.length > 0 || selectedItems.length > 0
    ? items.map((item) => ({
        month: item.month,
        results: item.results.filter((result) => {
          const isBareboneIncluded =
            selectedItens.length === 0 ||
            selectedItens.some(
              (selectedItem) => selectedItem.value === result.barebone
            );

          const isSegmentIncluded =
            selectedItems.length === 0 ||
            selectedItems.some(
              (selectedSegment) => selectedSegment.value === result.segment
            );

          return isBareboneIncluded && isSegmentIncluded;
        }),
      }))
    : items;

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      h={flexibleHeight ? undefined : "600px"}
    >
      <Flex justify="space-between" ps="0px" pe="5px" pt="0px" mb="10px">
        <Flex align="center" w="100%">
          <Text
            me="auto"
            color={titleColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Ranking de anormalidades
          </Text>
          {showFilterButton && (
            <Button
              ms="auto"
              align="center"
              justifyContent="center"
              bg={bgButton}
              w="37px"
              h="37px"
              lineHeight="100%"
              borderRadius="10px"
              onClick={() => history.push("/admin/ranking")}
            >
              <Icon as={MdTune} color="brand.500" w="24px" h="24px" />
            </Button>
          )}
        </Flex>
      </Flex>
      {!loading && (
        <Flex align="center" w="100%" >
          <Text
            me="auto"
            color={subtitleColor}
            fontSize="14px"
            lineHeight="100%"
          >
            {items.length
              ? `Período de ${startDate} à ${endDate}`
              : `Não existem anormalidades para o período de ${startDate} à ${endDate}`}
          </Text>
        </Flex>
      )}
      <Flex w="100%" flexDirection="column">
        <Box minH="500px" minW="90%" mt="auto">
          {loading ? (
            <AppSpinner />
          ) : (
            <BumpChart data={filteredItems} showToolbox={showToolbox}/>
          )}
        </Box>
      </Flex>
    </Card>
  );
};

RankingChartComponent.propTypes = {
  showFilterButton: PropTypes.bool,
  flexibleHeight: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  loading: PropTypes.bool,
  items: PropTypes.array,
  rankingSize: PropTypes.number,
  selectedItens: PropTypes.array,
  selectedItems: PropTypes.array,
};

const mapStateToProps = (state) => ({
  startDate: state.filters.startDate,
  endDate: state.filters.endDate,
  rankingSize: state.ranking.rankingSize,
  loading: state.ranking.loading,
  items: state.ranking.items,
  selectedItens: state.filters.barebones?.selected,
  selectedItems: state.filters.segments?.selected,
});

const RankingChart = connect(mapStateToProps, null)(RankingChartComponent);

export default RankingChart;
