import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { getCoOcurrencesCauseToPart } from "store/co_ocurrences/actions";
import GlobalFilters from "../../components/GlobalFilters";
import CauseToPartCoOcurrencesTable from "../../components/CauseToPartCoOcurrencesTable";

const CauseToPartCoOcurrencesView = function (props) {
  const { getCoOcurrencesCauseToPart } = props;
  useEffect(() => {
    getCoOcurrencesCauseToPart();
  }, []);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px">
        <GlobalFilters
          updateData={getCoOcurrencesCauseToPart}
          showBarebonesFilter
          showSegmentsFilter
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <CauseToPartCoOcurrencesTable flexibleHeight={true} />
      </SimpleGrid>
    </Box>
  );
};

CauseToPartCoOcurrencesView.propTypes = {
  getCoOcurrencesCauseToPart: PropTypes.func,
};

const CauseToPartCoOcurrences = connect(null, {
  getCoOcurrencesCauseToPart,
})(CauseToPartCoOcurrencesView);

export default CauseToPartCoOcurrences;
