import React from "react";
import { createRoot } from "react-dom/client";
import "assets/css/App.css";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { VisualizeChartsProvider } from "contexts/VisualizeChartsContext";
import PrivateRoute from "components/routes/PrivateRoute";
import { store, history } from "./store";
import { initApp } from "api/setup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

initApp();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <ThemeEditorProvider>
        <Router history={history}>
          <Switch>
            <VisualizeChartsProvider>
              <ToastContainer theme="colored" />
              <PrivateRoute path="/" component={AdminLayout} />
              <Route path="/auth" component={AuthLayout} />
            </VisualizeChartsProvider>
          </Switch>
        </Router>
      </ThemeEditorProvider>
    </ChakraProvider>
  </Provider>
);
