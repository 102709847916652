import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { getCoOcurrencesSymptomToPart } from "store/co_ocurrences/actions";
import GlobalFilters from "../../components/GlobalFilters";
import SymptomToPartCoOcurrencesTable from "../../components/SymptomToPartCoOcurrencesTable";

const SymptomToPartCoOcurrencesView = function (props) {
  const { getCoOcurrencesSymptomToPart } = props;
  useEffect(() => {
    getCoOcurrencesSymptomToPart();
  }, []);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px">
        <GlobalFilters
          updateData={getCoOcurrencesSymptomToPart}
          showBarebonesFilter
          showSegmentsFilter
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <SymptomToPartCoOcurrencesTable flexibleHeight />
      </SimpleGrid>
    </Box>
  );
};

SymptomToPartCoOcurrencesView.propTypes = {
  getCoOcurrencesSymptomToPart: PropTypes.func,
};

const SymptomToPartCoOcurrences = connect(null, {
  getCoOcurrencesSymptomToPart,
})(SymptomToPartCoOcurrencesView);

export default SymptomToPartCoOcurrences;
