import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, SimpleGrid } from "@chakra-ui/react";
import BlackListTable from "../components/BlacklistTable";

const BlackListView = function (props) {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px">
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <BlackListTable flexibleHeight showAddButton/>
      </SimpleGrid>
    </Box>
                

    );
}

BlackListView.propTypes = {
    //getCoOcurrencesPartToPart: PropTypes.func,
};

const BlackList = connect(null, {
    blacklist: PropTypes.func,
  })(BlackListView);

export default BlackList;