import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    blacklist: [],
    page: 1,
    pageCount: 1,
    };

const blacklistSlice = createSlice({
    name: "blacklist",
    initialState,
    reducers: {
        addToBlackListSuccess: (state, action) => {
            state.blacklist = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setBlacklist: (state, action) => {
            state.blacklist = action.payload.data;
            state.pageCount = action.payload.total_pages;
            state.page = action.payload.current_page;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
    },
});

export const {
    setLoading,
    setBlacklist,
    setPage,
} = blacklistSlice.actions;

export default blacklistSlice.reducer;