import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "./chakra-react-select";
import { FormControl, Container } from "@chakra-ui/react";
import { getBarebones, updatedSelectedBarebones } from "store/filters/actions";

const BarebonesFilterComponent = function (props) {
  const {
    allItems,
    selectedItems,
    getBarebones,
    updatedSelectedBarebones,
    updateData,
    containerStyle
  } = props;
  useEffect(() => {
    getBarebones();
  }, []);
  return (
    <Container style={containerStyle}>
      <FormControl>
        <Select
          isMulti={true}
          value={selectedItems}
          options={allItems}
          placeholder="Selecionar barebone..."
          closeMenuOnSelect={false}
          size="sm"
          onChange={(updatedItems) => {
            updatedSelectedBarebones(updatedItems);
            updateData();
          }}
        />
      </FormControl>
    </Container>
  );
};
BarebonesFilterComponent.propTypes = {
  selectedItems: PropTypes.array,
  allItems: PropTypes.array,
  updateData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  selectedItems: state.filters.barebones?.selected,
  allItems: state.filters.barebones?.all,
});

const SegmentsFilter = connect(mapStateToProps, {
  getBarebones,
  updatedSelectedBarebones,
})(BarebonesFilterComponent);

export default SegmentsFilter;
