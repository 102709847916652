import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "./chakra-react-select";
import { Container } from "@chakra-ui/react";
import { getSegments, updatedSelectedSegments } from "store/filters/actions";

const SegmentsFilterComponent = function (props) {
  const { allItems, selectedItems, getSegments, updatedSelectedSegments, updateData } =
    props;
  useEffect(() => {
    getSegments();
  }, []);
  return (
    <Container>
      <Select
        isMulti={true}
        value={selectedItems}
        options={allItems}
        placeholder="Selecionar segmento..."
        closeMenuOnSelect={false}
        size="sm"
        onChange={(updatedItems) => {
          updatedSelectedSegments(updatedItems);
          updateData();
        }}
      />
    </Container>
  );
};

SegmentsFilterComponent.propTypes = {
  selectedItems: PropTypes.array,
  allItems: PropTypes.array,
  updateData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  selectedItems: state.filters.segments?.selected,
  allItems: state.filters.segments?.all,
});

const SegmentsFilter = connect(mapStateToProps, {
  getSegments,
  updatedSelectedSegments,
})(SegmentsFilterComponent);

export default SegmentsFilter;
