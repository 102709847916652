import axios from "axios";
import {
  setLoadingBarebones,
  setLoadingSegments,
  getBarebonesSuccessful,
  getBarebonesError,
  getSegmentsSuccessful,
  getSegmentsError,
  setStartDate,
  setEndDate,
  setSelectedBarebones,
  setSelectedSegments,
} from "./slice";
import { toast } from "react-toastify";
import {
  API_ROUTE_FILTERS_BAREBONES,
  API_ROUTE_FILTERS_SEGMENTS,
} from "api/routes";

export const getBarebones = (name) => (dispatch) => {
  dispatch(setLoadingBarebones(true));
  axios
    .get(API_ROUTE_FILTERS_BAREBONES, { params: { name } })
    .then((response) => {
      dispatch(getBarebonesSuccessful(response?.data));
    })
    .catch((error) => {
      dispatch(getBarebonesError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações dos barebones. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingBarebones(false));
    });
};

export const getSegments = (name) => (dispatch) => {
  dispatch(setLoadingSegments(true));
  axios
    .get(API_ROUTE_FILTERS_SEGMENTS, { params: { name } })
    .then((response) => {
      dispatch(getSegmentsSuccessful(response?.data));
    })
    .catch((error) => {
      dispatch(getSegmentsError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações dos barebones. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingSegments(false));
    });
};

export const updateDateRange = (dates, dateStrings) => (dispatch) => {
  if (!dates) {
    dispatch(setStartDate(process.env.REACT_APP_DEFAULT_START_DATE));
    dispatch(setEndDate(process.env.REACT_APP_DEFAULT_END_DATE));
  } else {
    dispatch(setStartDate(dateStrings[0]));
    dispatch(setEndDate(dateStrings[1]));
  }
};

export const updatedSelectedBarebones = (barebones) => (dispatch) => {
  dispatch(setSelectedBarebones(barebones));
};

export const updatedSelectedSegments = (segments) => (dispatch) => {
  dispatch(setSelectedSegments(segments));
};
