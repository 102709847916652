import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Icon,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import React, { useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Card from "components/card/Card";
import { MdTune } from "react-icons/md";
import AppSpinner from "components/spinner/AppSpinner";
import { convertFloatToPercentage, roundFloat } from "helpers/converter";
import { Tooltip } from "antd";
import { GoQuestion } from "react-icons/go";

const columns = [
  {
    Header: "ANTECEDENTES",
    accessor: "antecedents",
  },
  {
    Header: "CONSEQUENTES",
    accessor: "consequents",
  },
  {
    Header: forwardRef(function (props, ref) {
      return (
        <Flex align="center" justify="center">
          <Text color="gray.400">CONFIANÇA</Text>
          <Tooltip title="Probabilidade condicional de ocorrer a peça consequente quando houver a presença da peça antecedente no chamado.">
            <Icon as={GoQuestion} m="5px" color="gray.400" w="18px" h="18px" />
          </Tooltip>
        </Flex>
      );
    }),
    accessor: "confidence",
  },
  {
    Header: forwardRef(function (props, ref) {
      return (
        <Flex align="center" justify="center">
          <Text color="gray.400">ASSOCIAÇÃO (LIFT)</Text>
          <Tooltip title="Grau de correlação entre peça antecedente e consequente. Quanto maior o lift, mais relacionadas as peças estão.">
            <Icon as={GoQuestion} m="5px" color="gray.400" w="18px" h="18px" />
          </Tooltip>
        </Flex>
      );
    }),
    accessor: "lift",
  },
];

const PartToPartCoOcurrencesTableComponent = function (props) {
  const {
    flexibleHeight,
    showFilterButton,
    loading,
    items,
    startDate,
    endDate,
  } = props;
  const history = useHistory();

  const data = useMemo(() => items.flat(1), [items]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  const titleColor = useColorModeValue("secondaryGray.900", "white");
  const subtitleColor = useColorModeValue("secondaryGray.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Card
      align="center"
      direction="column"
      w="100%"
      h={flexibleHeight ? undefined : "375px"}
      mb="10px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex justify="space-between" ps="0px" pe="5px" pt="0px" mb="10px">
        <Flex align="center" w="100%">
          <Text
            me="auto"
            color={titleColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Co-ocorrências entre peças
          </Text>
          {showFilterButton && (
            <Button
              ms="auto"
              align="center"
              justifyContent="center"
              bg={bgButton}
              w="37px"
              h="37px"
              lineHeight="100%"
              borderRadius="10px"
              onClick={() => history.push("/admin/co_ocurrences/part_to_part")}
            >
              <Icon as={MdTune} color="brand.500" w="24px" h="24px" />
            </Button>
          )}
        </Flex>
      </Flex>
      {!loading && (
        <Flex align="center" w="100%" mb="20px">
          <Text
            me="auto"
            color={subtitleColor}
            fontSize="14px"
            lineHeight="100%"
          >
            {items.length
              ? `Período de ${startDate} à ${endDate}`
              : `Não existem co-ocorrências para o período de ${startDate} à ${endDate}`}
          </Text>
        </Flex>
      )}
      {loading ? (
        <AppSpinner />
      ) : items.length > 0 ? (
        <Flex>
          <Table {...getTableProps()} variant="simple" color="gray.500">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="20px"
                      py="5px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="center"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = undefined;
                      if (cell.column.id === "antecedents") {
                        data = (
                          <Flex align="center" justify="start">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.id === "consequents") {
                        data = (
                          <Flex align="center" justify="start">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.id === "lift") {
                        data = (
                          <Flex align="center" justify="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {isNaN(cell.value) ? "" : roundFloat(cell.value)}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.id === "confidence") {
                        data = (
                          <Flex align="center" justify="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {isNaN(cell.value)
                                ? ""
                                : convertFloatToPercentage(cell.value)}
                            </Text>
                          </Flex>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
      ) : (
        <Flex justify="center" align="center" w="100%" h="100%" mb="10px">
          <Text color={titleColor} fontSize="16px" lineHeight="100%">
            Não foram encontradas co-ocorrências para este período
          </Text>
        </Flex>
      )}
    </Card>
  );
};

PartToPartCoOcurrencesTableComponent.propTypes = {
  showFilterButton: PropTypes.bool,
  flexibleHeight: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  loading: PropTypes.bool,
  items: PropTypes.array,
};

const mapStateToProps = (state) => ({
  startDate: state.filters.startDate,
  endDate: state.filters.endDate,
  ...state.coOcurrences.partToPart,
});

const PartToPartCoOcurrencesTable = connect(
  mapStateToProps,
  null
)(PartToPartCoOcurrencesTableComponent);

export default PartToPartCoOcurrencesTable;
