import axios from "axios";
import _ from "lodash";
import { 
  setLoading, 
  getCoOcurrencesPartToPartSuccessful, 
  getCoOcurrencesPartToPartError, 
  setLoadingSymptomToCause,
  getCoOcurrencesSymptomToCauseSuccessful,
  getCoOcurrencesSymptomToCauseError,
  setLoadingCauseToPart,
  getCoOcurrencesCauseToPartSuccessful,
  getCoOcurrencesCauseToPartError,
  setLoadingSymptomToPart,
  getCoOcurrencesSymptomToPartSuccessful,
  getCoOcurrencesSymptomToPartError
} from "./slice";
import { toast } from "react-toastify";
import { API_ROUTE_CO_OCURRENCES_PART_TO_PART, API_ROUTE_CO_OCURRENCES_SYMPTOM_TO_PART, API_ROUTE_CO_OCURRENCES_CAUSE_TO_PART, API_ROUTE_CO_OCURRENCES_SYMPTOM_TO_CAUSE } from "api/routes";

export const getCoOcurrencesPartToPart = () => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { startDate, endDate, barebones, segments } = getState().filters;
  const selectedBarebones = _.map(
    barebones.selected,
    (item) => item.value
  ).join(",");
  const selectedSegments = _.map(segments.selected, (item) => item.value).join(
    ","
  );
  axios
    .get(API_ROUTE_CO_OCURRENCES_PART_TO_PART, {
      params: {
        start_date: startDate?.split("/").reverse().join("-"),
        end_date: endDate?.split("/").reverse().join("-"),
        barebones: selectedBarebones,
        segments: selectedSegments,
      },
    })
    .then((response) => {
      dispatch(getCoOcurrencesPartToPartSuccessful(response?.data));
    })
    .catch((error) => {
      dispatch(getCoOcurrencesPartToPartError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de co-ocorrências em peças. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const getCoOcurrencesSymptomToPart = () => (dispatch, getState) => {
  dispatch(setLoadingSymptomToPart(true));
  const { startDate, endDate, barebones, segments } = getState().filters;
  const selectedBarebones = _.map(
    barebones.selected,
    (item) => item.value
  ).join(",");
  const selectedSegments = _.map(segments.selected, (item) => item.value).join(
    ","
  );
  axios
    .get(API_ROUTE_CO_OCURRENCES_SYMPTOM_TO_PART, {
      params: {
        start_date: startDate?.split("/").reverse().join("-"),
        end_date: endDate?.split("/").reverse().join("-"),
        barebones: selectedBarebones,
        segments: selectedSegments,
      },
    })
    .then((response) => {
      dispatch(getCoOcurrencesSymptomToPartSuccessful(response?.data));
    })
    .catch((error) => {
      dispatch(getCoOcurrencesSymptomToPartError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de co-ocorrências em peças. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingSymptomToPart(false));
    });
}

export const getCoOcurrencesCauseToPart = () => (dispatch, getState) => {
  dispatch(setLoadingCauseToPart(true));
  const { startDate, endDate, barebones, segments } = getState().filters;
  const selectedBarebones = _.map(
    barebones.selected,
    (item) => item.value
  ).join(",");
  const selectedSegments = _.map(segments.selected, (item) => item.value).join(
    ","
  );
  axios
    .get(API_ROUTE_CO_OCURRENCES_CAUSE_TO_PART, {
      params: {
        start_date: startDate?.split("/").reverse().join("-"),
        end_date: endDate?.split("/").reverse().join("-"),
        barebones: selectedBarebones,
        segments: selectedSegments,
      },
    })
    .then((response) => {
      dispatch(getCoOcurrencesCauseToPartSuccessful(response?.data));
    })
    .catch((error) => {
      dispatch(getCoOcurrencesCauseToPartError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de co-ocorrências em peças. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingCauseToPart(false));
    });
}

export const getCoOcurrencesSymptomToCause = () => (dispatch, getState) => {
  dispatch(setLoadingSymptomToCause(true));
  const { startDate, endDate, barebones, segments } = getState().filters;
  const selectedBarebones = _.map(
    barebones.selected,
    (item) => item.value
  ).join(",");
  const selectedSegments = _.map(segments.selected, (item) => item.value).join(
    ","
  );
  axios
    .get(API_ROUTE_CO_OCURRENCES_SYMPTOM_TO_CAUSE, {
      params: {
        start_date: startDate?.split("/").reverse().join("-"),
        end_date: endDate?.split("/").reverse().join("-"),
        barebones: selectedBarebones,
        segments: selectedSegments,
      },
    })
    .then((response) => {
      dispatch(getCoOcurrencesSymptomToCauseSuccessful(response?.data));
    })
    .catch((error) => {
      dispatch(getCoOcurrencesSymptomToCauseError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de co-ocorrências em peças. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingSymptomToCause(false));
    });
}