import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { getOutliers } from "store/outliers/actions";
import GlobalFilters from "../components/GlobalFilters";
import OutliersChart from "../components/OutliersChart";

const OutliersView = function (props) {
  const { getOutliers } = props;
  useEffect(() => {
    getOutliers();
  }, []);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px">
        <GlobalFilters
          updateData={getOutliers}
          showBarebonesFilter
          showSegmentsFilter
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <OutliersChart flexibleHeight />
      </SimpleGrid>
    </Box>
  );
};

OutliersView.propTypes = {
  getOutliers: PropTypes.func,
};

const Outliers = connect(null, {
  getOutliers,
})(OutliersView);

export default Outliers;
