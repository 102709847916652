export const convertFloatToPercentage = (float) => {
  // Multiply the float by 100 to get the percentage value
  const percentage = float * 100;
  // Use the toLocaleString() method to add commas as thousand separators
  const formattedPercentage = percentage.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // Append the percentage symbol
  const percentageString = formattedPercentage + "%";
  return percentageString;
}

export const roundFloat = (float) => {
  // Use the toLocaleString() method to add commas as thousand separators
  const formatted = float.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${formatted}`;
}
